import fetchHelper from "../../helpers/fetchHelper";

export default class MutationEffect {

    static async apiList(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.mutations
        } else return null

    }


}

