import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import MutationReducer from "./mutation/MutationReducer";
import LanguageReducer from "./language/LanguageReducer";
import ErrorReducer from "./error/ErrorReducer";
import AuthenticationReducer from "./authentication/AuthenticationReducer";
import PaymentReducer from "./payment/PaymentReducer";
import RentalReducer from "./rental/RentalReducer";


const rootReducer = (history) => {

    const reducerMap = {
        router: connectRouter(history),
        payment: PaymentReducer.reducer,
        rental: RentalReducer.reducer,
        mutation: MutationReducer.reducer,
        language: LanguageReducer.reducer,
        error: ErrorReducer.reducer,
        authentication: AuthenticationReducer.reducer,
    };

    return combineReducers(reducerMap);
};

export default rootReducer