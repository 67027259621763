import ActionUtility from '../../helpers/ActionUtility'
import LanguageEffect from "./LanguageEffect";

export default class LanguageAction {
    static SET = 'LanguageAction.SET';
    static DEFAULT = 'LanguageAction.DEFAULT';

    static default() {
        return async (dispatch, getState) => {

            const browserLanguage = navigator.language
            // Getting the first value for catching language codes like en-EN or nl-NL
            const languageCode = browserLanguage.split('-')[0];
            let language = null

            switch (languageCode) {
                case('nl'):
                case('en'):
                case('de'):
                    language = languageCode
                    break;
                default:
                    language = 'en'
                    break;

            }

            await ActionUtility.createThunkEffect(dispatch, LanguageAction.SET, LanguageEffect.set, language);
        };
    }

    static set(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, LanguageAction.SET, LanguageEffect.set, value);
        };
    }

}