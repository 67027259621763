import AuthenticationAction from "./AuthenticationAction";

export default class AuthenticationReducer {

    static initialState = {
        auth: false,
        login: false,
        partnerUser: false,
        authTime: false,
        timeOut: false,
        accessToken: null
    };

    static reducer(state = AuthenticationReducer.initialState, action) {

        switch (action.type) {

            case AuthenticationAction.REQUEST_ACCESS_TOKEN:
                return {
                    ...state,
                };

            case AuthenticationAction.REQUEST_ACCESS_TOKEN + '_FINISHED':

                localStorage.setItem('accessToken', action.payload.response.accessToken.access_token);

                return {
                    ...state,
                    auth: action.payload.response,
                    accessToken: action.payload.response.accessToken.access_token
                };

            case AuthenticationAction.LOGIN:

                return {
                    ...state,
                    login: false
                };

            case AuthenticationAction.LOGIN + '_FINISHED':
                return {
                    ...state,
                    login: action.payload
                };

            case AuthenticationAction.GET_USER_BY_PIN:
                return {
                    ...state,
                    partnerUser: false
                };

            case AuthenticationAction.GET_USER_BY_PIN + '_FINISHED':
                return {
                    ...state,
                    partnerUser: action.payload,
                    timeOut: false
                };

            case AuthenticationAction.TIME_OUT:
                return {
                    ...state,
                };

            case AuthenticationAction.TIME_OUT + '_FINISHED':
                return {
                    ...state,
                    partnerUser: false,
                    timeOut: true
                };

            case AuthenticationAction.LOGOUT + '_FINISHED':
                return {
                    ...state,
                    login: false,
                    partnerUser: false,
                };

            case AuthenticationAction.UNSET_USER:
                return {
                    ...state,
                    login: false
                };

            case AuthenticationAction.UNSET_USER + '_FINISHED':
                return {
                    ...state,
                    partnerUser: false,
                    authTime: false,
                    timeOut: false
                };

            default:
                return state;
        }
    }
}