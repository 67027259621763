import fetchHelper from "../../helpers/fetchHelper";

export default class CustomerEffect {

    static async apiGetDetail(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }


}

