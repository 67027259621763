const FormatHolidayAddress = (data) => {

    if (data) {

        let returnData = ''

        if (data.name) {
            returnData = data.name + ', ' + data.street + ' ' + data.number + ', ' + data.zip_code
        } else {
            returnData = data.street + ' ' + data.number + ', ' + data.zip_code
        }

        if (data.item) {
            returnData += ' - '
        }

        if (data.item && data.item.name) {
            returnData += ' ' + data.item.name
        }

        if (data.item && data.item.number) {
            returnData += ' ' + data.item.number
        }

        if (data.item_number) {
            returnData += ' - ' + data.item_number
        }

        return returnData

    } else {
        return ''
    }


}

export default FormatHolidayAddress