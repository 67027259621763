import ActionUtility from "../../helpers/ActionUtility";
import ErrorEffect from "./ErrorEffect";

export default class ErrorAction {

    static SET_ERROR = 'ErrorAction.SET_ERROR';
    static CATCH_ERROR = 'ErrorAction.CATCH_ERROR';
    static UNSET_ERROR = 'ErrorAction.UNSET_ERROR';


    static setError(value) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ErrorAction.SET_ERROR, ErrorEffect.setError, value);
        }

    }

    static catchError(error) {



        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ErrorAction.CATCH_ERROR, ErrorEffect.catchError, error);
        }

    }

    static unsetError() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ErrorAction.UNSET_ERROR, ErrorEffect.unsetError);
        }

    }


}
