export default class ErrorEffect {

    static setError(value) {

        switch (value.status.code) {
            case(401):
                // Unauthorized
                return {
                    displayError: 'Uw sessie is verlopen of uw pincode is ergens anders ingevoerd. Log a.u.b. opnieuw in.',
                    forceLogout: true
                }
            default:
                // All other errors
                return {
                    displayError: value.status.message,
                    forceLogout: false
                }
        }
    }

    static catchError(error) {

        return {
            displayError: 'Er heeft zich een fout voorgedaan',
            forceLogout: false
        }
    }

    static unsetError() {
        return true
    }
}


