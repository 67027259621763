import LanguageAction from "./LanguageAction";

export default class LanguageReducer {

    static initialState = {
        active: 'nl',
        options: [
            {
                key: 'nl',
                value: 'Nederlands'
            },
            {
                key: 'de',
                value: 'Deutsch'
            },
            {
                key: 'en',
                value: 'English'
            },
        ]
    };

    static reducer(state = LanguageReducer.initialState, action) {
        switch (action.type) {

            case LanguageAction.DEFAULT + '_FINISHED':

                return {
                    ...state,
                    active: action.payload
                }

            case LanguageAction.SET + '_FINISHED':

                return {
                    ...state,
                    active: action.payload
                }

            default:
                return state;
        }
    }
}

