const DisplayPrice = (number, discount = false) => {

    let fixedNumber = Number.parseFloat(number).toFixed(2);


    if (discount) {
        discount = Number(discount) / 100;
        fixedNumber =  Number(fixedNumber - (fixedNumber * discount)).toFixed(2);
    }

    return String(fixedNumber).replace('.', ",");
}

export default DisplayPrice