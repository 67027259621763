import RentalAction from "./RentalAction";

export default class RentalReducer {

    static initialState = {
        data: null
    };

    static reducer(state = RentalReducer.initialState, action) {
        switch (action.type) {

            case RentalAction.API_GET_DETAIL + "_FINISHED":
                return{
                    ...state,
                    data: action.payload
                }

            default:
                return state;
        }
    }
}

