import MutationAction from "./MutationAction";

export default class MutationReducer {

    static initialState = {
        list: null
    };

    static reducer(state = MutationReducer.initialState, action) {
        switch (action.type) {

            case MutationAction.API_GET_LIST + '_FINISHED':

                return{
                    ...state,
                    list: action.payload
                }

            default:
                return state;
        }
    }
}

